import Performer from './Performer/Performer.js';
import Matter from 'matter-js';
import Particle from './Renderer/Particle.js';
import * as Tone from "tone"
import * as Tonal from "@tonaljs/tonal";
import SoundControl from './SoundControl.js';
import AtmosphereSource from './SoundSource/AtmosphereSource.js';

/**
 * Handles everything music theory
 */
class Conductor extends SoundControl {
    
    constructor(renderer, performers, particles, engine) {
        super();
        this.renderer = renderer;
        this.performers = [];
        this.particles = particles;
        this.engine = engine;
        this.particleRadius = 20;

        // init audio
        this.initAudio();

        // References to our Performer Objects
        this.dronePerformer = null;
        this.padPerformer = null;
        this.rhythmPerformer1 = null;
        this.rhythmPerformer2 = null;

        // As Atmospheres have no visual representation, they are just a SoundSource and not a Performer
        this.atmosphereSource = null;

        // rhythm necklaces generated by euclidean rhythm
        this.curRhythm1 = this.bresenhamEuclidean(this.randomIntFromRange(1, 7), this.randomIntFromRange(7, 22));
        this.curRhythm2 = this.shiftRhythm(this.curRhythm1, 3);

        this.evolveMusic();

        Tone.Volume.value = -3;
    }

    /**
     * @notice Sets up callbacks to evolve our composition over time
     *         Randomizes the rhythm every 8 measures.
     */
    evolveMusic() {


        // every 8 measures we pick a new rhythm 
        const self = this;
        Tone.Transport.scheduleRepeat(function(time){
            self.curRhythm1 = self.bresenhamEuclidean(self.randomIntFromRange(1, 7), self.randomIntFromRange(7, 22));
            self.curRhythm2 = self.shiftRhythm(self.curRhythm1, 3);
        }, "8m");

        // every 16 measures, pick a new atmosphere
        Tone.Transport.scheduleRepeat(function(time){
            if(self.atmosphereSource) {
                self.atmosphereSource = null;
            }
            self.atmosphereSource = new AtmosphereSource(null, self, self.particles.length);
            self.atmosphereSource.play();
        }, "16m");     
    }

    /**
     * A performance is made up of at most 1 drone, 1 pad, 2 rhythms
     * Right now we can only handle just that.
     * I plan to expand this so that if multiple pads exist, the conductor cross fades between them
     */
    registerActor(newPerformer) {
         if(newPerformer.getType() === 'drone') {
            this.dronePerformer = newPerformer;
            this.dronePerformer.init(this.getMidNote(-14,  this.scaleNotes));
        }
        else if(newPerformer.getType() === 'pad') {
            this.padPerformer = newPerformer;
            this.padPerformer.init();
        }
        else if(newPerformer.getType() === 'rhythm') {
            if(this.rhythmPerformer1 == null) {
                this.rhythmPerformer1 = newPerformer;
                this.rhythmPerformer1.init();
            }
            else if(this.rhythmPerformer2 == null) {
                this.rhythmPerformer2 = newPerformer;
                this.rhythmPerformer2.init('2n', '16n', 7);
            }
        }
        this.performers.push(newPerformer);
    }

    registerAtmosphere(source) {
        this.atmosphereSource = source;
    }

    getChordsForNote(scaleNotes) {
        let chords = [];

        for(let i=0; i < scaleNotes.length; i++) {
            let chord = [];
            chord[0] = this.getMidNote(i, scaleNotes);
            chord[1] = this.getMidNote(i+2, scaleNotes);
            chord[2] = this.getMidNote(i+4, scaleNotes);
            chord[3] = this.getMidNote(i+6, scaleNotes);
            chords.push(chord);
        }
        return chords;
    }


    initAudio() {
        Tone.Transport.bpm.value = 70;
    }

    play() {
        // check to make sure play() isn't called over and over again
        if(this.isPlaying) return;
        this.isPlaying = true;
        Tone.Transport.start();
        if(this.dronePerformer) this.dronePerformer.play();
        if(this.padPerformer) this.padPerformer.play();
        if(this.rhythmPerformer1) this.rhythmPerformer1.play();
        if(this.rhythmPerformer2) this.rhythmPerformer2.play();
        if(this.atmosphereSource) this.atmosphereSource.play();

    }

    pause() {
        this.isPlaying = false;
        Tone.Transport.pause();
        if(this.dronePerformer) this.dronePerformer.pause();
        if(this.padPerformer) this.padPerformer.pause();
        if(this.rhythmPerformer1) this.rhythmPerformer1.pause();
        if(this.rhythmPerformer2) this.rhythmPerformer2.pause();
        if(this.atmosphereSource) this.atmosphereSource.pause();
    }

    /**
     * @notice Callback method used to a Performer can notify the Conductor a note was just played
     * and the Conductor can go ahead and create some visuals (if desired). This allows us to maintain
     * the pattern of 1 algorithm producing both audio and visuals.
     * @param {*} performer The performer who just played
     */
    notePlayed(soundSource) {
        if(soundSource.getType() === 'pad') {
            this.particles.unshift(new Particle(soundSource.getPerformer().x, soundSource.getPerformer().y, 
                                            this.particleRadius, soundSource.getColor(), this.engine));
        }
        else if(soundSource.getType() === 'rhythm') {
            this.particles.unshift(new Particle(soundSource.getPerformer().x, soundSource.getPerformer().y, 
                                            this.particleRadius, soundSource.getColor(), this.engine));   
            // rhythm performers use a water color renderer that updates on new notes
            soundSource.getPerformer().getRenderer().addParticle();
        }
        else if(soundSource.getType() === 'drone') {
      
        }
    }



    /**
     * @notice As the Conductor is responsible for maintaining mellifluous musicality across all Performers
     * this function is used by RhythmSource object to determine if they should play on a given measure.
     * This allows the Conductor to evolve and change the rhythm necklaces over time.
     * @param {*} rhythmSource The RhythmSource object asking if it should play
     */
    shouldPlay(rhythmSource) {
        if(rhythmSource === this.rhythmPerformer1.soundSource) {
            let rhythm = this.curRhythm1.shift();
            this.curRhythm1.push(rhythm);
            return rhythm === 1;
        }
        else if(rhythmSource === this.rhythmPerformer2.soundSource) {
            let rhythm = this.curRhythm2.shift();
            this.curRhythm2.push(rhythm);
            return true;
        }
        return false; // should never be reached
    }



}


export default Conductor;


